<template>
  <div class="w-full">
    <form
      @submit.prevent="putSMS(this.id, 'List updated successfully')"
      class="shadow space-y-8 divide-y divide-gray-200 z-10 p-5"
      method="POST"
    >
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Update SMS List Form.
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Enter new list name below.
            </p>
          </div>
          <br />
          <div class="pt-8 space-y-6 sm:pt-0 sm:space-y-5">
            <div class="space-y-6 sm:space-y-5">
              <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
              >
                <label
                  for="customer-account"
                  class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    v-model="putForm.name"
                    required=""
                    type="text"
                    name="cti-name"
                    id="cti-name"
                    class="rounded max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300"
                    :placeholder="this.smsName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5 z-100">
        <div class="flex justify-end z-100">
          <a
            @click="$emit('closeEditForm')"
            href="javascript:void(0)"
            class="z-100 bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            {{ $t("ticket.cancel") }}
          </a>
          <button
            type="submit"
            :class="
              `rounded z-100 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium  body__table--header text-white`
            "
          >
            {{ $t("ticket.send") }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

export default {
  props: ["smsName", "id"],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      putForm: {
        name: "",
      },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    async putSMS(id, msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/sms/recipientLists/${id}`,
        params: {
          customerAccount: `${this.account}`,
          name: `${this.putForm.name}`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          this.putForm.name = "";
          this.closePutForm = !this.closePutForm;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch(function(error) {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
  },
  mounted() {
    this.putForm.name = this.smsName;
  },
  watch: {
    smsName: function(val) {
      this.putForm.name = val;
    },
  },
};
</script>

<style></style>
