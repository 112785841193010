<template>
  <div class="z-10 sticky top-0" :key="this.closeForm">
    <div
      :class="`flex flex-wrap justify-between`"
    >
      <div class="col-start-1 col-end-2 text-right pt-2">
        <button
          :class="[
            `rounded group body__header--button text-white sm:text-sm lg:text-base xl:text-base md:text-base inline-flex items-center text-base font-medium shadow p-2 mr-0`,
          ]"
          @click="this.$emit('openForm')"
        >
          <span class="uppercase">new SMS</span>
          <!-- <ChevronDownIcon
            :class="[
              open ? `encom_dark_text` : `encom_dark_text`,
              `ml-2 h-5 w-5`,
            ]"
            aria-hidden="true"
          /> -->
        </button>
      </div>
      <div class="col-end-3">
        <div class="pt-2 pb-2">
          <button
            @click.prevent="
              deleteAllList(
                this.id,
                'Delete current CTI',
                'Are you sure you want to delete all destinations?',
                'Confirm',
                'Go back',
                'Destinations deleted successfully'
              )
            "
            type="button"
            class="uppercase rounded inline-flex ml-10 items-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-red-500 hover:bg-red-700 focus:outline-none"
          >
            Delete Lists
          </button>
        </div>
      </div>
    </div>

    <!-- SMS form -->
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";

const account = localStorage.getItem("account");

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "SMSHeader",
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ConfirmationDialog,
    AskConfirmationDialog,
    Loading,
    ChevronDownIcon,
  },
  data() {
    return {
      account,

      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
};
</script>

<style></style>
