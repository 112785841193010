<template>
  <table class="min-w-full divide-y divide-gray-200 shadow">
    <thead class="body__table--header">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
          v-for="header in tableHeaders"
          :key="header.name"
        >
          <div class="inline-flex">
            <span>
              {{ $t(`${header.name}`) }}
            </span>
          </div>
        </th>
        <th scope="col" class="relative px-6 py-3">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </thead>

    <tbody class="bg-white divide-y divide-gray-200">
      <tr
        v-for="sms in this.smsList"
        :key="sms.id"
        class="hover:bg-gray-100 cursor-pointer"
      >
        <td
          class="px-6 py-4 whitespace-nowrap"
          @click.prevent="goToList(sms.id)"
        >
          <div class="text-sm text-gray-900">
            {{ sms.name }}
          </div>
        </td>
        <UpdateSMS
          :id="sms.id"
          :name="sms.name"
          @openEditForm="$emit('openEditForm', sms.name, sms.id)"
          @activeBanner="$emit('activeBanner')"
          @successMsg="$emit('successMsg', $event)"
          @success="$emit('success', $event)"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

import UpdateSMS from "../components/UpdateSMS.vue";
import EditSMSForm from "../components/EditSMSForm.vue";

const account = localStorage.getItem("account");

export default {
  name: "SMSList",
  components: {
    UpdateSMS,
    EditSMSForm,
  },
  data() {
    return {
      account,
      smsList: [],
      tableHeaders: [{ name: "name" }],
      openEditForm: false,
    };
  },
  methods: {
    getList() {
      const options = {
        method: "GET",
        url: `${this.$cookie.getCookie("API")}/api/v1/sms/recipientLists`,
        params: { customerAccount: this.account },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((response) => {
          console.log(response);
          this.smsList = response.data;
          this.$emit("sms-list", this.smsList);
        })
        .catch((error) => {
          this.errorHandling(error);
        });
    },
    goToList(id) {
      this.$router.push(`/sms-detail/${this.account}/${id}`);
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style></style>
