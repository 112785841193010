<template>
 <div class="bg-gray-100">
  <div class="pt-5">
   <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="text-center">
     <h2 class="text-3xl font-extrabold encom_primary_text sm:text-4xl lg:text-5xl">
      {{ $t("smsSserviceTitle") }}
     </h2>
     <p class="mt-4 text-xl text-gray-600">
      {{ $t("smsSserviceSubTitle") }}
     </p>
    </div>
   </div>
  </div>

  <div class="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
   <div class="relative">
    <div class="absolute inset-0 h-1/2 bg-gray-100" />
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
     <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
      <div class="flex-1 bg-white px-6 py-8 lg:p-12">
       <h3 class="text-2xl font-extrabold encom_primary_text sm:text-3xl">
        {{ $t("smsSserviceCartTitle") }}
       </h3>
       <p class="mt-6 text-base text-gray-500">{{ $t("smsSserviceCartDetail") }}.</p>
       <div class="mt-5">
        <!--     <SwitchGroup as="div">
         <SwitchLabel as="h3" class="text-lg leading-6 font-medium encom_dark_text" passive>
          Membership
         </SwitchLabel>
         <div class="sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm text-gray-500">
           <SwitchDescription>
            By checking this box, I declare that I have read the general terms and conditions of the
            service and I agree to respect them.
           </SwitchDescription>
          </div>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
           <Switch
            v-model="enabled"
            :class="[
             enabled ? 'body__table--header' : 'bg-gray-200',
             'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 encom_ring_color',
            ]"
           >
            <span class="sr-only">Use setting</span>
            <span
             :class="[
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
             ]"
            >
             <span
              :class="[
               enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
               'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
             >
              <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
               <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
               />
              </svg>
             </span>
             <span
              :class="[
               enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
               'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
             >
              <svg class="h-3 w-3 encom_ouvert_ticket_text" fill="currentColor" viewBox="0 0 12 12">
               <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
               />
              </svg>
             </span>
            </span>
           </Switch>
          </div>
         </div>
        </SwitchGroup> -->
        <div
         v-show="this.acknowledgeTC === false"
         class="bg-yellow-50 border-l-4 border-yellow-400 p-1 mt-3 rounded w-3/4"
        >
         <div class="flex">
          <div class="flex-shrink-0 ml-2">
           <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
           <p class="text-sm text-yellow-700">
            Our general terms and conditions must be accepted to activate the service.
           </p>
          </div>
         </div>
        </div>
       </div>
       <div class="mt-8">
        <div class="flex items-center">
         <h4
          class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase encom_primary_text"
         >
          {{ $t("smsSserviceListTitle") }}.
         </h4>
         <div class="flex-1 border-t-2 border-gray-200" />
        </div>
        <ul
         role="list"
         class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
        >
         <li
          v-for="feature in includedFeatures"
          :key="feature"
          class="flex items-start lg:col-span-1"
         >
          <div class="flex-shrink-0">
           <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <p class="ml-3 text-sm text-gray-700">
           {{ $t(feature) }}
          </p>
         </li>
        </ul>
       </div>
      </div>
      <!--  <div
       class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
      >
       <p class="text-lg leading-6 font-medium encom_dark_text">Pay now, access it now</p>
       <div class="mt-4 flex items-center justify-center">
        <p>
         <span class="text-5xl font-extrabold encom_dark_text"> €0.06</span>
         {{ " " }}
         <span class="text-base font-medium text-gray-500">/sms</span>
        </p>
       </div>
       <p class="mt-4 text-sm">
        <a href="#" class="font-medium text-gray-500 underline">
         Learn about our terms and conditions
        </a>
       </p>
       <div class="mt-6">
        <div class="rounded-md shadow">
         <a
          @click="$emit('activate-account', enabled)"
          class="cursor-pointer flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white body__table--header"
         >
          Get Access
         </a>
        </div>
       </div>
       <div class="mt-4 text-sm">
        <p class="font-medium encom_dark_text">Taxes not included</p>
       </div>
      </div> -->
     </div>

     <div v-if="!service.enabled">
      <div class="max-w-7xl mx-auto pt-16 px-4 sm:pt-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
       <div class="max-w-xl">
        <h2
         class="text-2xl font-extrabold encom_primary_text sm:text-2xl sm:tracking-tight lg:text-3xl"
        >
         {{ $t("smsSserviceFooterTitle") }}.
        </h2>
        <p class="mt-5 text-lg text-gray-500">{{ $t("smsSserviceFooterDetail") }}.</p>
       </div>
       <div class="w-full max-w-xs">
        <ul role="list">
         <li
          v-for="person in sales"
          :key="person.id"
          class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
         >
          <div class="w-full flex items-center justify-between p-6 space-x-6">
           <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
             <h3 class="text-gray-900 text-sm font-medium truncate">
              {{ person.name }}
             </h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm truncate">
             {{ person.title }}
            </p>
           </div>
           <!-- <img
            class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            :src="` https://telecom0555-admin.bluerocktel.net/avatar/user/${person.id}`"
            alt=""
           /> -->
           <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
             <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
             />
            </svg>
           </span>
          </div>
          <div>
           <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
             <a
              :href="`mailto:${person.emailShown}`"
              class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
             >
              <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Email</span>
             </a>
            </div>
            <div v-show="person.phoneDirect || person.phone" class="-ml-px w-0 flex-1 flex">
             <a
              :href="`tel:${person.phoneDirect ? person.phoneDirect : person.phone}`"
              class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
             >
              <PhoneIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Appeler</span>
             </a>
            </div>
           </div>
          </div>
         </li>
        </ul>
       </div>
      </div>
     </div>
     <div v-else>
      <div
       class="mx-auto max-w-7xl px-6 sm:py-20 mb-3 lg:flex lg:items-center lg:justify-between lg:px-8"
      >
       <h2 class="text-3xl font-bold tracking-tight encom_primary_text sm:text-4xl">
        Accédez à votre plateforme SMS
        <br />
        dès maintenant.
       </h2>
       <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
        <a
         href="https://sms.encom.tel"
         target="_blank"
         class="flex gap-2 rounded-md encom_primary encom_primary_hover px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
        >
         Accéder au service SMS
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-5 h-5"
         >
          <path
           fill-rule="evenodd"
           d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
           clip-rule="evenodd"
          />
          <path
           fill-rule="evenodd"
           d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
           clip-rule="evenodd"
          />
         </svg>
        </a>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { ref } from "vue";
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { MailIcon, PhoneIcon, CheckCircleIcon, ExclamationIcon } from "@heroicons/vue/solid";

const includedFeatures = [
 "smsSserviceList1",
 "smsSserviceList2",
 "smsSserviceList3",
 "smsSserviceList4",
];

const service = JSON.parse(localStorage.getItem("services")).find(
 (service) => service.key === "sms"
);

const people = [
 {
  name: "Jane Doe",
  title: "Encom Sales Representative",
  role: "Sales",
  email: "janecooper@example.com",
  telephone: "+1-202-555-0170",
  imageUrl:
   "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
 },
];
const timestamp = new Date().getTime();
export default {
 name: "ServiceSMS",
 props: ["acknowledgeTC", "sales"],
 components: {
  ExclamationIcon,
  MailIcon,
  PhoneIcon,
  CheckCircleIcon,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
 },
 setup() {
  const enabled = ref(false);

  return {
   timestamp,
   includedFeatures,
   enabled,
   people,
   service,
  };
 },
};
</script>
