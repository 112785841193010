<template>
  <div class="z-10">
    <div class="z-10 w-full transform shadow">
      <div class="max-w-auto mx-auto bg-white px-4 py-2">
        <form
          @submit.prevent="sendSMS('SMS sent successfully')"
          class=" bg-white space-y-8 divide-y divide-gray-200 py-5 px-5"
        >
          <div class="space-y-8 divide-y divide-gray-200">
            <div class="pt-8">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Recipient Information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Add the recipients of your SMS here, separated by commas. You
                  can also combine this with a mailing list.
                </p>
              </div>
              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label
                    for="phone-number"
                    class="block text-sm font-medium text-gray-700"
                    >Phone Number</label
                  >
                  <div class="mt-1 relative shadow-sm">
                    <div class="absolute inset-y-0 left-0 flex items-center">
                      <label for="country" class="sr-only">Country</label>
                      <select
                        @change="changePrefix($event)"
                        id="country"
                        name="country"
                        class="focus:ring-transparent focus:border-transparent h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm "
                      >
                        <option value="fr">FR</option>
                        <option value="irl">IRL</option>
                        <option value="ca">CA</option>
                        <option value="us">US</option>
                      </select>
                    </div>
                    <input
                      v-model="this.form.recipient"
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      required
                      class="rounded block w-full pl-16 sm:text-sm border-gray-300 "
                    />
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label
                    for="mailing-list"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Mailing Lists
                  </label>
                  <div class="mt-1">
                    <select
                      v-model="this.form.mailinglist"
                      id="mailing-list"
                      name="mailing-list"
                      class="shadow-sm   block w-full sm:text-sm border-gray-300 "
                    >
                      <option>choose...</option>
                      <option>List 1</option>
                      <option>List 2</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="inline-flex">
                <button
                  @click="addNumbers"
                  type="submit"
                  :class="
                    `mt-2 rounded ml-0 inline-flex justify-center p-1 border border-transparent shadow-sm text-sm font-medium body__table--header text-white`
                  "
                >
                  Add number
                </button>
                <div v-if="recipientList.length">
                  <span
                    v-for="recipient in recipientList"
                    :key="recipient"
                    class="ml-1"
                    >{{ recipient }}</span
                  >
                </div>
              </div>
            </div>

            <div class="pt-8">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Type of SMS
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Choose the type of SMS.
                </p>
              </div>
              <div class="mt-6">
                <fieldset
                  class="mt-2 flex items-center"
                  v-for="(value, type) in smsType"
                  :key="value"
                >
                  <input
                    v-model="this.form.type"
                    id="prefix-number"
                    name="prefix-number"
                    type="radio"
                    class="rounded h-4 w-4 border-gray-300"
                    :value="value"
                  />
                  <label
                    for="prefix-number"
                    class="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {{ type }}
                  </label>
                </fieldset>
              </div>
              <p
                v-if="this.form.type === 'm'"
                class="font-bold mt-2 -mb-5 text-sm text-gray-500"
              >
                Be careful ! For all marketing SMS, the message will finish by
                "STOP AT 36111". It's mandatory in order to provide your
                recipients with a means of unsubscribing and to comply with the
                obligations of the CNIL.
              </p>
            </div>

            <div class="pt-8">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  <div class="inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                    <span class="pl-2">Message SMS</span>
                  </div>
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Type the content of the SMS
                </p>
              </div>
              <div class="mt-6">
                <div class="sm:col-span-3">
                  <label
                    for="sms-message"
                    class="block text-sm font-medium text-gray-700"
                  >
                    SMS Message
                  </label>
                  <div class="mt-1">
                    <textarea
                      v-model="this.form.message"
                      placeholder="Type your message here."
                      rows="5"
                      type="text"
                      name="sms-message"
                      id="sms-message"
                      :maxlength="this.form.type === 'm' ? '446' : '459'"
                      :class="
                        ` shadow-sm  block w-full sm:text-sm border-gray-300`
                      "
                    />
                  </div>
                  <p
                    class="mt-1 text-sm text-gray-500"
                    v-if="this.form.type === 'm'"
                  >
                    {{ this.form.message.length + this.marketingSuffix.length }}
                    characters for {{ numberOfSms() }} SMS
                  </p>
                  <p class="mt-1 text-sm text-gray-500" v-else>
                    {{ this.form.message.length }}
                    characters for {{ numberOfSms() }} SMS
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  @click="this.$emit('closeForm')"
                  type="button"
                  class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  {{ $t("ticket.cancel") }}
                </button>
                <button
                  type="submit"
                  :class="
                    `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white`
                  "
                >
                  {{ $t("ticket.send") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      account,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
      marketingSuffix: "STOP AT 36111",
      form: {
        recipient: "+33",
        message: "",
        type: "",
      },
      recipientList: [],
      smsType: { Marketing: "m", Transactional: "t" },
    };
  },
  methods: {
    addNumbers() {
      this.recipientList.push(this.form.recipient);
      this.form.recipient = "+33";
    },
    numberOfSms() {
      //  for each 160 characters, add +1 to an index
      let smsCount = 1;
      let charCount = this.form.message.length;
      if (this.form.type === "m") {
        charCount = this.form.message.length + this.marketingSuffix.length;
      }
      if (charCount > 160) {
        smsCount++;
      }
      if (charCount > 306) {
        smsCount++;
      }
      if (charCount > 459) {
        smsCount++;
      }
      return smsCount;
    },
    changePrefix(event) {
      if (event.target.value === "fr") {
        this.form.recipient = "+33";
      } else if (event.target.value === "ca" || event.target.value === "us") {
        this.form.recipient = "+1";
      } else if (event.target.value === "irl") {
        this.form.recipient = "+353";
      }
    },
    sendSMS(msg) {
      let message = "";
      if (this.form.type === "m") {
        message = this.form.message + "\n" + this.marketingSuffix;
      } else {
        message = this.form.message;
      }
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/sms/singleMessage`,
        params: {
          customerAccount: `${this.account}`,
          recipient: `${this.form.recipient}`,
          message: `${message}`,
          type: `${this.form.type}`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      if (message.length < 460) {
        axios
          .request(options)
          .then((res) => {
            this.form.message = "";
            this.form.type = "";
            this.form.recipient = "+33";
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
            // this.sendMessage(msg);
          })
          .catch((err) => {
            window.alert(
              "Oups, something went wrong.\nReason: " + err.response.statusText
            );
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", this.errorDataResponse);
          });
      } else {
        window.alert(
          "Oups, something went wrong.\nReason: " +
            "Maximum characters allowed : 459 characters. \n" +
            "Your message have " +
            message.length +
            " characters"
        );
      }
    },

    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
