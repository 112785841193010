<template>
  <div class="z-0 sticky top-0 border rounded-md">
    <!-- New list form -->
    <div>
      <div class="z-10 w-full transform">
        <div class="max-w-auto mx-auto px-4 py-2">
          <form
            @submit.prevent="
              createListOfRecipients('List created successfully')
            "
            class=" bg-white space-y-8 divide-y divide-gray-200 py-5 px-5"
          >
            <div class="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Create new list
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">
                    Enter list information below.
                  </p>
                </div>
                <div
                  class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                >
                  <div class="sm:col-span-3">
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      List name
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="this.form.name"
                        type="text"
                        name="list-name"
                        id="list-name"
                        class="rounded shadow-sm focus:encom_border_input  block w-full sm:text-sm border-gray-300 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5 grid grid-cols-12">
              <div class="col-span-10 rounded-md bg-blue-50 p-2 w-1/2">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                      You will be able to import your contacts file after
                      creating the list.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-span-2 flex justify-end">
                <a
                  href="javascript:void(0)"
                  @click="$emit('closeForm')"
                  class="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  {{ $t("ticket.cancel") }}
                </a>
                <button
                  type="submit"
                  :class="
                    `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium  body__table--header text-white`
                  "
                >
                  {{ $t("ticket.send") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";
import { InformationCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ConfirmationDialog,
    InformationCircleIcon,
  },
  data() {
    return {
      form: {
        name: "",
      },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    createListOfRecipients(msg) {
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/sms/recipientLists`,
        params: {
          customerAccount: localStorage.getItem("account"),
          name: this.form.name,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((response) => {
          // this.sendMessage(msg);
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch((error) => {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
